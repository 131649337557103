

/** Zone JS is required by Angular itself. **/
import 'zone.js/dist/zone';


/** APPLICATION IMPORTS **/

(window as any).global = window;

// Third party JS imports
// // Page loader
// import './vendor/pace/pace.min.js';
// // Charts
// import './vendor/Chart.min.js';
// // User tour
// import 'hopscotch';

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
